var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3d6b2ca823c20f7cc3eadd34c96259aedbfc502f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { getSentryInitDefaults } from '@ours/web-utils';
import * as Sentry from '@sentry/nextjs';

const stage = process.env.NEXT_PUBLIC_STAGE || process.env.STAGE || "";

Sentry.init({
  dsn: 'https://9ef4a969b5344cf6adf9e370d7875e42@o1064262.ingest.sentry.io/6061882',
  ...getSentryInitDefaults({ stage }),
});
